<!--
 * @Date: 2024-08-22 09:44:09
 * @Author: 大年
 * @Version: 1.0
 * @Contact: 353036652@qq.com
-->
<template>
  <div class="m-user-set-avatar">
    <div id="wrapper-el" class="view3d-wrapper view3d-square">
      <canvas class="view3d-canvas"></canvas>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute } from "vue-router";
import View3D from "@egjs/view3d";
import "@egjs/view3d/css/view3d-bundle.min.css";

export default {
  name: "TdAvatarPage",
  setup() {
    const route = useRoute();
    const state = reactive({});

    onMounted(() => {
      const { avatar } = route.query;
      init3DView(avatar);
    });

    const init3DView = (src) => {
      new View3D("#wrapper-el", {
        src,
        // poster: "https://assets.mech.world/space/avatar/20221017/162007_7101_2.glb",
        rotate: {
          disablePitch: true,
        },
        // center:  ["50%", "100%", "50%"] //位置
      });
    };

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.m-user-set-avatar {
  width: 100%;
  min-height: 100vh;
  background-color: #120a36;
  padding: 20px 25px;
}
#wrapper-el {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.view3d-wrapper {
  width: 100%;
  height: 100vh;
}
</style>
